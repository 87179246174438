.picture{
    margin: 0 auto;
    width: 15%;
}
.position3 {
    width: 100%;
    text-align: center;
    position:absolute;
}
p{
  margin: 0 1.5rem 1.5rem 1.5rem;
}
.picture4{
  margin: 0 auto;
  width: 15%;
}
.picture5{
  margin: 0 auto;
  width: 15%;
}

@media (max-width: 959px) {
    .picture{
        padding-top: 1rem;
        margin: 0 auto;
        width: 20%;
    }
    .position3 {
        width: 100%;
        text-align: center;
        position:absolute;
    }
    p{
      margin: 0 1.5rem 1.5rem 1.5rem;
    }
    .picture4{
      padding-top: 1rem;
      margin: 0 auto;
      width: 20%;
    }
    .picture5{
      padding-top: 1rem;
      margin: 0 auto;
      width: 20%;
  }
}

@media (max-width: 480px) {
  .picture{
      margin: 0 auto;
      width: 40%;
  }
  .position3 {
    width: 100%;
    /*min-width: 300px;*/
    text-align: center;
    position:absolute;
  }
  .small_p{
    font-size: 1rem;
    margin-top: 2rem;
  }
  .picture4{
    margin: 0 auto;
    width: 40%;
  }
  .picture5{
    margin: 0 auto;
    width: 45%;
  }
}