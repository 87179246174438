.picture{
    margin: 0 auto;
    width: 40%;
}
.position6 {
  width: 100%;
  text-align: center;
 }
p{
  font-size: 1.15rem;
}
.pictures2{
  width: 25%;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 959px) {
    .picture{
        margin: 0 auto;
        width: 40%;
    }
    .position6 {
      width: 100%;
      text-align: center;
    }
    p{
      font-size: 1.15rem;
    }
    .pictures2{
      width: 40%;
      max-width: 300px;
      text-align: center;
      margin: 0 auto;
    }
}

@media (max-width: 480px) {
  .picture{
      margin: 0 auto;
      width: 40%;
  }
  .position6 {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
  p{
    font-size: 1.15rem;
  }
  .pictures2{
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }
}