.score {
  width: 32vw;
  padding: 1rem 1.5rem 1rem 0;
}

.headerScore {
  width: 32vw;
  padding-right: 5rem;
}

.login_logo {
  width: 35%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 70%;
  text-align: center;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display_img {
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide_content {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide_content1 {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

ion-item.folder {
  --detail-icon-color: rgb(255, 196, 0);
  --detail-icon-opacity: 1;
}

ion-toolbar.color {
  --background: #fffcf2;
}

ion-content.color {
  --background: #fffcf2;
}
ion-item.color {
  --background: #fffcf2;
}

ion-item-divider {
  --background: #ffeadb;
}

@media (max-width: 959px) {
  .score {
    width: 32vw;
    padding: 1rem 1.5rem 1rem 0;
  }

  .headerScore {
    width: 32vw;
    padding-right: 5rem;
  }

  .login_logo {
    width: 50%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo {
    width: 70%;
    text-align: center;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .display_img {
    text-align: center;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slide_content {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slide_content1 {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ion-toolbar.color {
    --background: #fffcf2;
  }

  ion-content.color {
    --background: #fffcf2;
  }
  ion-item.color {
    --background: #fffcf2;
  }

  ion-item-divider {
    --background: #ffeadb;
  }
}

@media (max-width: 480px) {
  .score {
    width: 32vw;
    padding: 1rem 1.5rem 1rem 0;
  }

  .headerScore {
    width: 32vw;
    padding-right: 5rem;
  }

  .login_logo {
    width: 70%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo {
    width: 70%;
    text-align: center;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .display_img {
    text-align: center;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slide_content {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slide_content1 {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ion-toolbar.color {
    --background: #fffcf2;
  }

  ion-content.color {
    --background: #fffcf2;
  }
  ion-item.color {
    --background: #fffcf2;
  }

  ion-item-divider {
    --background: #ffeadb;
  }
}
