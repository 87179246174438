/*p {
  font-size: 1.15rem;
  color: black;
  /*margin: 0 1rem 1rem 1rem;*/
/*}*/

ion-card-title {
  font-size: 1.5rem;
}

/*span {
  font-size: 1.75rem;
}*/
/*
.center_m5 {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}*/
.center {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  width: 100%;
  border-bottom: solid black;
  text-align: center;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title_m {
  width: 100%;
  border-bottom: solid black;
  text-align: center;
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translate(-50%, -50%);
}
