  .logo{
      padding-top: 2rem;
      height: 35%;
     
  }
  .position{
      text-align: center;
      padding-top: 8rem;
  }
  p {
    font-size: 1.15rem;
    color: black;
  }

@media (max-width: 959px) {
    .logo{
        padding-top: 2rem;
        height: 35%;    
    }
    .position{
        text-align: center;
        padding-top: 10rem;
    }
    p {
        font-size: 1.15rem;
        color: black;
      }

}

@media (max-width: 480px) {
    .logo{
        margin-top: -2.25rem;
        height: 35%;    
    }
    .position{
        text-align: center;
        padding-top: 10rem;
    }
    p {
        font-size: 1.15rem;
        color: black;
      }
}